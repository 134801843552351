import { FC } from 'react';
import { ICurriculum } from '../../Types/Response';

type  Props={
  curriculum:ICurriculum,
  currentMonth:number,
  children:React.ReactNode
}
const Day :FC<Props>=({curriculum,currentMonth,children})=>{

  if(currentMonth !== curriculum.lecture_date.getMonth()) return <></>

  return <>{children}</>
}
export default Day
