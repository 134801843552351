import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {Routes, Route} from 'react-router-dom';
import AuthUserProvider from './Components/AuthUserProvider';
import {MessageProvider} from './Components/MessageContext';
import Calendar from './Components/Pages/Calendar';
import Curriculums from './Components/Pages/Curriculums';
import Error from './Components/Pages/Error';
import HelpBookCell from './Components/Pages/Help/HelpBookCell';
import HelpHome from './Components/Pages/Help/HelpHome';
import Home from './Components/Pages/Home';
import Login from './Components/Pages/Login';
import TestPage from './Components/Pages/TestPage';
import PrivateRoute from './Components/PrivateRoute';
import GenericTemplate from './Components/Templates/GenericTemplate';
import Theme from './Components/ThemeProvider';

//
// Sentry.init({
//   dsn: "https://78ee18e07f1d40ceb959b30933635c43@o1096680.ingest.sentry.io/6117430",
//   integrations: [new Integrations.BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

function App() {
    return (
        <div className="App">
            <AuthUserProvider>
                <ThemeProvider theme={Theme}>

                    <MessageProvider>
                            <Routes>
                                <Route index element={<PrivateRoute><Home/></PrivateRoute>}/>
                                <Route path="curriculums" element={<PrivateRoute><Curriculums/></PrivateRoute>}/>
                                <Route path="calendar" element={<PrivateRoute><Calendar/></PrivateRoute>}/>
                                <Route path="login" element={<GenericTemplate><Login/></GenericTemplate>}/>
                                <Route path="error" element={<GenericTemplate><Error/></GenericTemplate>}/>
                                <Route path="help" element={<GenericTemplate><HelpHome/></GenericTemplate>}/>
                                <Route path="help/bookCell" element={<GenericTemplate><HelpBookCell/></GenericTemplate>}/>
                                <Route path="test" element={<GenericTemplate><TestPage/></GenericTemplate>}/>
                            </Routes>
                    </MessageProvider>
                </ThemeProvider>
            </AuthUserProvider>
        </div>
    );
}

export default App;
