import React, { FC } from 'react';
import { styled, Typography } from '@mui/material';
import { backgroundColor, color, week } from '../../Types/Calendar';
import Conf from '../../Types/config';

const SDayHead = styled('div')<{ targetDate: Date }>(({targetDate}) => {
  const c = color[week(targetDate)]
  const b = backgroundColor[week(targetDate)]

  return {
    color: c,
    backgroundColor: b,
    margin: '0 0 0.5em 0',
    borderRadius: 10,
    padding: '5px',
  }
})

type CellDateHeadProps = {
  date: Date
}

const CellDateHead: FC<CellDateHeadProps> = ({date}) => {
  const dayOfWeekStr = Conf.weeksLabel

  return (
    <SDayHead targetDate={date}>
      <Typography>{date.getDate()}({dayOfWeekStr[date.getDay()]})</Typography>
    </SDayHead>
  )
}

export default CellDateHead
