import React, { FC, useEffect } from 'react';
import {
	Alert, FormControlLabel,
	Grid,
	Stack,
	styled, Switch,
	Table,
	TableBody, TableCell,
	TableContainer, TableHead,
	TableRow,
	Typography,
	useMediaQuery
} from '@mui/material'
import useCurriculumCell from '../../Hooks/useCurriculumCell';
import useFetchcurriculums from '../../Hooks/useFetchCurriculums';
import { ICurriculum } from '../../Types/Response';
import Conf from '../../Types/config';
import BookingCompleted from '../Organisms/BookingCompleted';
import Theme from '../ThemeProvider';
import Box from '../atom/Box';
import DefaultButton from '../atom/Buttons/DefaultButton';
import BackdropLoading from '../comm/BackDrop';
import CalendarStatus from '../comm/CalendarStatus';
import Day from '../comm/Day';
import DayTitle from '../comm/DayTiltle';
import SwipeDialog from '../comm/swipeDialog';

const SHeadCell = styled(TableCell)(() => ({
	textAlign: 'center',
	Width:  '15em',
	verticalAlign: 'top',
	border: '1px solid #ccc',
	overflow: 'hidden',
	fontWeight:'bold',
	padding: '0.5em',
	backgroundColor:'#fefff6f7'
}))

const SAlert = styled(Alert)(() => ({
	width:'100%',
	margin: '1em 0',
	padding: '5px',
	position: 'sticky',
	top: 0,
	'& .MuiAlert-message': {
		width: '100%'
	}
}))


type STableCellProps = {
	displayValue: 'flex' | 'table-cell' | 'none'
	backgroundColor: string,
	cursor: string
}

const STableCell = styled(TableCell, {
	shouldForwardProp: (prop) =>
		prop !== 'displayValue' && prop !== 'backgroundColor'
})<STableCellProps>(({ displayValue, backgroundColor ,cursor}) => ({
	border: '1px solid #ccc',
	borderSpacing: 0,
	backgroundColor,
	padding: '0.5rem',
	borderCollapse: 'collapse',
	width: displayValue === 'flex' ? '100%':'13%',
	verticalAlign: 'top',
	display: displayValue,
	alignItems: 'stretch',
	vAlign:'middle',
	cursor,
}))

const STable = styled(Table)(() => ({
	margin: '1rem auto',
	width: '100%',
	zoom:1,
	[Theme.breakpoints.down('sm')]: {
		zoom:0.6
	}



}))


const Calendar: FC = () => {
	const ref = React.createRef<HTMLDivElement>()
	const isMdScreen = useMediaQuery(Theme.breakpoints.down('md'));

	const {
		user,
		curriculums,
		loading,
		calendarDate,
		handleCalendarDown,
		handleCalendarUP,
		fetchRequest,
		status,
		handleGoInput,
		handleSubmit,
		weeks,
		isBookOver,
		handleCheangeCurritulums,
		isPost
	} = useFetchcurriculums()
	useEffect(() => {
		const abortCtrl = new AbortController()
		fetchRequest()

		return ()=>abortCtrl.abort()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])
	const {isBooking,chipStyle,serviceColor,label} = useCurriculumCell()

	const [isFlex, setIsFlex] = React.useState(false)
	const [fSize, setFSize] = React.useState<string>('100%')
	const today = new Date()
	const endMounthDay = new Date(calendarDate.getFullYear(),calendarDate.getMonth()+1,0)
	const serviceProvision = user?.service_provision === 0 ? endMounthDay.getDate() - 8 : Number(user?.service_provision)
	const bookCount = curriculums.filter(value => value.is_book).length
	const weekDay = ['one', 'two', 'three', 'four', 'five', 'six']
	// const {serviceLabel}=useCurriculumCell()
	const SButtonGrid = styled(Grid)(() => ({
		position: 'sticky',
		bottom: isMdScreen ? '3em' : '3em',
		left: 0,
		backgroundColor:'rgba(255, 255, 255, 0.9)',
		width: '100%',
	}))
	const displayCell=(curriculum:ICurriculum)=>{
		if (calendarDate.getMonth() !== curriculum.lecture_date.getMonth() && isFlex) return 'none'
		if (isFlex) return 'flex'

		return 'table-cell'
	}
	const handleFormClick = () => {
		ref.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
		handleSubmit()


	}

	const handleCellClick = (curriculum:ICurriculum):void => {
		if (isBooking(curriculum) || status !== 'input') {
			handleCheangeCurritulums({ ...curriculum, is_edit: !curriculum.is_edit, edit_book: !curriculum.edit_book })
		}
	}

	const handleFormBack = () => {
		ref.current?.scrollIntoView({behavior: 'smooth', block: 'start'})
		handleGoInput()

	}

	useEffect(()=>{
		if(isFlex && isMdScreen){
			setFSize('100%')
		}else if (isMdScreen){setFSize('80%')}


	},[isFlex,isMdScreen])
	if (loading) return <><BackdropLoading showBackdrop={loading}/></>

	return (
		 <>
			<Box>
				<Grid container alignItems="center" ref={ref}>
					<Grid item xs={12} md={10} sx={{textAlign:{'xs':'center','md':'left'}}}>
						<Typography variant="h5">
							{calendarDate.getFullYear()}年{Number(calendarDate.getMonth()) + 1}月 カリキュラム
						</Typography>
					</Grid>
					{status ==='input' && curriculums.filter(v=>v.is_edit).length === 0 &&
					<Grid item xs={12} md={2} sx={{mt:{xs:2,md:0,textAlign:{'xs':'center','md':'right'}}}}	 justifyContent="center" >

							<DefaultButton name="preMonth" onClick={handleCalendarDown} sx={{display: today.getMonth() !== calendarDate.getMonth() ? 'inline' : 'none'}} label="当月"/>

							<DefaultButton name="nextMonth" onClick={handleCalendarUP} sx={{display: today.getMonth() !== calendarDate.getMonth() ? 'none' : 'inline'}} label="翌月"/>
					</Grid>}
				</Grid>
			</Box>
			 <Typography sx={{ textAlign: 'right' }}>
				 <FormControlLabel
					 control={
						 <Switch checked={!isFlex} onClick={() => setIsFlex(!isFlex)} />
					 }
					 label="カレンダー形式"

				 />
			 </Typography>

			 {weeks.length ===0 ?
				 <SAlert sx={{textAlign:'center'}}>
				 <Typography variant="h5">カリキュラムが見つかりませんでした。</Typography>
				 </SAlert>
				 :
				 <>
					 <SwipeDialog/>
			<CalendarStatus status={status} isAlert={{isBookOver}} serviceProvision={serviceProvision || 0 } bookCount={bookCount}/>
			 <BookingCompleted status={status} curriculums={curriculums} />


			<TableContainer>
				{status === 'input' && <STable>
					<TableHead>
						<TableRow sx={{display:isFlex ? 'none':'table-row'}}>
							<SHeadCell>日</SHeadCell>
							<SHeadCell>月</SHeadCell>
							<SHeadCell>火</SHeadCell>
							<SHeadCell>水</SHeadCell>
							<SHeadCell>木</SHeadCell>
							<SHeadCell>金</SHeadCell>
							<SHeadCell>土</SHeadCell>
						</TableRow>

					</TableHead>
				<TableBody>
					{weeks.map((w,key)=>
						<TableRow key={`row-${weekDay[key]}`}>
					{w.map((c)=>
						<STableCell displayValue={displayCell(c)} backgroundColor="#fff" cursor={isBooking(c ) ? 'pointer':'nomal'} onClick={()=>handleCellClick(c)}>
							<Day curriculum={c}  currentMonth={calendarDate.getMonth()}>
								<Typography component="div"  sx={{overflow:'hidden',fontSize:fSize,width:'95%',display:'block',textAlign: isFlex ? 'center':'left'}} >	{c.lecture_date.getDate()}{isFlex && <>({Conf.weeksLabel[c.lecture_date.getDay()]})</>}<br/>
									<DayTitle curriculum={c}/>
													{c.lecture_id > 1 && isBooking(c) && <>
													<Typography
														component="div"
														sx={{textAlign:'center',
														width:'96%',
															fontSize:fSize,
														m:'1 auto',
														borderRadius:2,
														border:`1px solid ${serviceColor(c)}`,
														backgroundColor:chipStyle(c) === 'outlined' ?'none': serviceColor(c),
														color:chipStyle(c) === 'outlined' ? serviceColor(c) : '#fff'}}>
														{label(c)}

													</Typography>
													</>}
								</Typography>
							</Day>
						</STableCell>
					)}

						</TableRow>
						)}
				</TableBody>
				</STable>}
			</TableContainer>
					 {status !== 'send' &&
        <SButtonGrid container alignItems="center">
          <Grid item xs={12} sx={{my: 2, textAlign: 'center'}}>
            <Stack direction="row" spacing={2} justifyContent="center">
							{status === 'view' && <DefaultButton onClick={handleFormBack} label="選択画面に戻る"/>}

              <DefaultButton name="go" onClick={handleFormClick} disabled={isPost}
                             label={status === 'input' ? '確認' : '送信'}/>
            </Stack>

          </Grid>
        </SButtonGrid>
			}
			</>}
		</>
	)
};

export default Calendar
