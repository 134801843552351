import React, {FC} from 'react';
import {styled, TableCell} from '@mui/material'
import {green, grey, orange} from '@mui/material/colors';
import {TableCellProps} from '@mui/material/TableCell/TableCell';
import {ICurriculum} from '../../Types/Response';

type styleProp = {
    display: 'flex' | 'none'
}


const SCellDay = styled(TableCell)<styleProp>(({display}) => ({
    textAlign: 'center',
    minWidth:  '15em',
    verticalAlign: 'top',
    border: '1px solid #eee',
    overflow: 'hidden',
    padding: '2em 0.5em',
    '@media screen and (max-width: 768px) ': {
        border: '1px solid #eee',
        display,
        width: '100%',
    }
}))

type CellProps = Pick<TableCellProps, 'children'>

interface Props extends CellProps {
    isMobileDisplay: boolean
    currentMonth: number
    curriculum: ICurriculum
}

const CellDay: FC<Props> = ({curriculum, isMobileDisplay,currentMonth, children}) => {
    const yesterday = new Date()
    const day = new Date(curriculum.lecture_date)



    const selectClasses = (): string => {
        if (yesterday > day && currentMonth === day.getMonth()) return grey['300']
        if (curriculum.book_rank > curriculum.service_limit && curriculum.is_book) return green['100']
        if (curriculum.is_book) return orange['50']

        return '#fff'
    }

    return <SCellDay key={`cell-${curriculum.id}`} display={isMobileDisplay ? 'flex' : 'none'}
                     style={{
                         backgroundColor: selectClasses(),
                         opacity: yesterday > day && yesterday.getMonth() === day.getMonth() ? 20 : 100,
                     }}>
        {children}
    </SCellDay>
}
export default CellDay
