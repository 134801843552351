import { useLayoutEffect, useState } from 'react';
import { Dialog, DialogContentText, Typography, useMediaQuery } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Theme from '../ThemeProvider';
import DefaultButton from '../atom/Buttons/DefaultButton';

const SwipeDialog = () =>{
  const [open, setOpen] = useState<boolean>( false);



  const handleClose = () => {
    setOpen(false);
  };
  const isMdScreen = useMediaQuery(Theme.breakpoints.down('md'));
  useLayoutEffect(() => {
    const isShowSwipeDialog = localStorage.getItem('isShowSwipeDialog')

    if(isShowSwipeDialog === null) {
      setOpen(isMdScreen)
      if(isMdScreen) localStorage.setItem('isShowSwipeDialog', isMdScreen ? 'true' : 'false')
    }
  },[isMdScreen])

  return <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      カレンダー上をスワイプできます。
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <Typography sx={{textAlign:'center'}}><img src="/swip.png" height="50" alt=""/></Typography>
        カレンダー上をスワイプすることで、カレンダーの情報を確認・操作できます。      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <DefaultButton label="閉じる" onClick={handleClose} autoFocus />
    </DialogActions>
  </Dialog>
}
export default SwipeDialog
