import React, { useContext, useEffect, useMemo, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'
import { AxiosError } from 'axios'
import { useError } from '../Components/ErrorContext'
import { MessageContext } from '../Components/MessageContext'
import { TStatus } from '../Types/BookStatus'
import {
  IAttendResponse,
  ICurriculum,
  ICurritulums,
  ILectureMaster,
  SystemMessage,
  TOffice,
  TUser
} from '../Types/Response'
import Conf from '../Types/config'
import useCalendarCell, { TWeeks } from './useCalendarCell'
import useHttp from './useHttp'

export interface IResponse {
  lecture_master: Array<ILectureMaster> | undefined;
  curriculums: Array<ICurriculum>,
  error: AxiosError | null;
  loading: boolean
  systemMessage?: undefined | SystemMessage
  user: TUser | undefined
  office: TOffice | undefined
}

interface IPostCurriculum {
  id: string,
  is_book: boolean,
  start_time: string | undefined,
  end_time: string | undefined,
}


interface IPostCurritulums {
  books: IPostCurriculum[];
}

const useFetchcurriculums = () => {
  const { setContextError } = useError()

  const [calendarDate, setCalendarDate] = useState<Date>(new Date())
  const [, setTimeDaialogOpen] = useState(false)
  const [, setEditBookIndex] = useState<number>(0)
  const [isBookOver, setIsBookOver] = useState<boolean>(false)
  const [status, setStatus] = useState<TStatus>('input')
  const [, setBookCount] = useState<number>(0)
  const [weeks, setWeeks] = useState<TWeeks>([[]])
  const [isPost, setIsPost] = useState<boolean>(true)
  const ref = React.createRef<HTMLDivElement>()
  const { createCurriculumCalendar } = useCalendarCell()
  // const {status,curriculums,handleCheangeCurritulums,isBookOver,handleSubmut,handleGoInput,handleBookCount,setCurriclums}=useCalendar(handlePostRequest,user)
  const messageContext = useContext(MessageContext)
  const [response, setResonse] = useState<IResponse>({
    lecture_master: undefined,
    curriculums: [],
    error: null,
    loading: false,
    user: undefined,
    office: undefined,
    systemMessage: undefined

  })

  const { client } = useHttp()

  const handleError = useMemo(() => (reason: AxiosError) => {
    setResonse(prevState => ({ ...prevState, loading: false }))
    if (reason?.response?.status === 401) {
      setContextError(401, '再ログインが必要です')
    }
    if (reason?.response?.status === 500) {
      setContextError(500, 'サーバに不具合が起きています')
    }
  }, [setContextError])

  const handleBookCount = () => {
    const countBook = response.curriculums.filter(value => value.edit_book).length
    setBookCount(countBook)
    const serviceProvision = response.user?.service_provision ? response.user?.service_provision : 0
    setIsBookOver(false)
    if (countBook >= serviceProvision && status === 'input') setIsBookOver(false)

  }

  const fetchRequest = () => {
    setResonse(prevState => ({ ...prevState, loading: true }))
    const targetMonth = calendarDate.getMonth() + 1
    const http = client()
    setResonse(prevState => ({ ...prevState, currilumus: [] }))
    setWeeks([])
    http.get<ICurritulums | null>(`${Conf.apiIrl}calendar/${calendarDate.getFullYear()}/${targetMonth}?t=${new Date().getTime()}`)
      .then((res) => {
        const currilumus: Array<ICurriculum> = []
        res.data?.curriculums?.forEach(value => {
          currilumus.push({
            ...value,
            lecture_date: new Date(value.lecture_date),
            is_edit: false,
            service_limit: Number(value.service_limit),
            end_time: value.start_time,
            edit_book: value.is_book,
            is_updated: false
          })
        })

        setResonse({
          lecture_master: undefined,
          curriculums: currilumus,
          user: res.data?.user,
          office: res.data?.office,
          error: null,
          loading: false
        })
        setWeeks(() => createCurriculumCalendar(currilumus))
        handleBookCount()
      })
      .catch((error: AxiosError) => {
        handleError(error)

      })
  }

  const handleCalendarUP = () => {
    const currentDate = calendarDate
    currentDate.setMonth(currentDate.getMonth() + 1)
    setCalendarDate(currentDate)
    fetchRequest()
  }
  const handleCalendarDown = () => {
    const currentDate = calendarDate
    currentDate.setMonth(currentDate.getMonth() - 1)
    setCalendarDate(currentDate)
    fetchRequest()
  }

  const handleChange = (key: number, event: SelectChangeEvent, col: 'title' | 'lecture_id' = 'lecture_id') => {
    const responseNw = response.curriculums?.map((curriculun, index) => (
      index === key ? Object.assign(curriculun, { [col]: event.target.value }) : curriculun
    ))
    setResonse(prevState => ({ ...prevState, response: responseNw }))
  }
  const handlePostRequest = () => {
    if (response.curriculums !== undefined) {
      setResonse(prevState => ({ ...prevState, loading: true }))
      const post: IPostCurritulums = { books: [] }

      response.curriculums?.forEach(value => {
        if (value.is_edit) post.books.push({
          id: value.id,
          is_book: value.edit_book,
          start_time: value.start_time,
          end_time: value.end_time
        })
      })
      // http.post<ICurritulums<string> | null>('curriculums/update',{curriculums:[{id:1,lecture_date:'2021-12-29',lecture_id:0}]})
      const http = client()
      http.put<IAttendResponse>('calendar/update', post)
        .then((res) => {
          const c = response.curriculums.concat()
          res.data.inserted.forEach((v) => {
            c.filter((e) => e.id === v.id)[0].is_updated = true
            // c.filter((e) => e.id === v.id)[0].edit_book = true
            // c.filter((e) => e.id === v.id)[0].is_book = true
          })
          res.data.deleted.forEach((v) => {
            c.filter((e) => e.id === v.id)[0].is_updated = true
            // c.filter((e) => e.id === v.id)[0].edit_book = false
            // c.filter((e) => e.id === v.id)[0].is_book = false
          })
          console.log(c)
          setResonse(prevState => ({
            ...prevState,
            curriculums: c,
            loading: false,
            systemMessage: { text: '予約しました。', isDelete: true }
          }))
          // fetchRequest()
        })
        .catch((error: AxiosError) => {
          handleError(error)


        })

    }
  }

  const handleCheangeCurritulums = (curriculum: ICurriculum) => {
    if ((isBookOver && curriculum.edit_book || curriculum.lecture_id === 0)) return
    const newCurriculums = response.curriculums.concat()
    const index = curriculum.lecture_date.getDate() - 1
    newCurriculums[index] = curriculum
    setResonse(prevState => ({ ...prevState, curriculums: newCurriculums }))
    setWeeks(() => createCurriculumCalendar(newCurriculums))

    if (curriculum.is_time === 1 && curriculum.is_edit) {
      setTimeDaialogOpen(true)
      setEditBookIndex(index)
    }
  }
  const handleSubmit = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    if (status === 'input') {
      setStatus(() => 'view')

    } else {
      setStatus(() => 'send')
      handlePostRequest()
    }
  }
  // const callBackCheangeCurritulums = useCallback(handleCheangeCurritulums,[setResonse,curriculums])
  const handleGoInput = () => {
    setStatus(() => 'input')

  }
  //
  //
  useEffect(() => {
      const countBook = response.curriculums.filter(value => value.edit_book).length
      setBookCount(countBook)
      const serviceProvision = response.user?.service_provision ? response.user?.service_provision : 0
      setIsBookOver(false)
      if (countBook > serviceProvision && status === 'input') setIsBookOver(false)
      if (response.curriculums.filter((value) => value.is_edit).length > 0 && !isBookOver) {
        setIsPost(false)
      } else {
        setIsPost(true)
      }
    }
    , [isBookOver, response.curriculums, response.user?.service_provision, status])

  useEffect(() => {
    let message: string
    if (status === 'send') {
      message = '予約・キャンセルしました。'
      messageContext.handleSetMessage(message)
    } else if (status === 'input') {
      message = 'カリキュラムを読み込みました'
      messageContext.handleSetMessage(message)
    }

  }, [status, messageContext, response.systemMessage])


  useEffect(() => {
    if (response.loading) messageContext.handleSetMessage('')

  }, [messageContext, response.loading])
  useEffect(() => {
    const abortCtrl = new AbortController()
    if (response.curriculums.length === 0) fetchRequest()

    return () => abortCtrl.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    ...response,
    fetchRequest,
    calendarDate,
    handleChange,
    handlePostRequest,
    handleCalendarUP,
    handleCalendarDown,
    status,
    handleCheangeCurritulums,
    handleBookCount,
    handleGoInput,
    handleSubmit,
    weeks,
    isBookOver,
    isPost
  }
}
export default useFetchcurriculums
