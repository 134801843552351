import React, { FC } from 'react';
import {  styled, Typography } from '@mui/material';
import useCurriculumCell from '../../Hooks/useCurriculumCell';
import { backgroundColor, color, week } from '../../Types/Calendar';
import { ICurriculum } from '../../Types/Response';

type  Props = {
  curriculum: ICurriculum
}
const SName = styled(Typography)<{ targetDate: Date }>(({ targetDate }) => {
  const c = color[week(targetDate)];
  const b = backgroundColor[week(targetDate)];

  return {
    color: c,
    backgroundColor: b,
    margin: '0 auto 1em auto',
    fontSize: '90%',
    textAlign: 'center',
    width: '100%',
    borderRadius: 10,
    zIndex: -2,
  }
});
const DayTitle: FC<Props> = ({ curriculum }) => {
 const {serviceLabel}=useCurriculumCell()

  return <>

    <SName targetDate={curriculum.lecture_date}>{curriculum.name}</SName>
    {curriculum.lecture_id >1 && <Typography sx={{fontSize:'90%',textAlign:'center'}}>{serviceLabel(curriculum)}</Typography>}
  </>
}
export default DayTitle;
