import React, {FC} from 'react';
import {Alert, styled, Typography,} from '@mui/material';
import {useNavigate} from 'react-router';

import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import {TStatus} from '../../Types/BookStatus';
import SecondButton from '../atom/Buttons/SecondButton';

const steps = [
    '入力',
    '確認',
    '送信完了',
];
interface CalendarStepperProp {
    step:string
}
const CalendarStepper:FC<CalendarStepperProp> = ({step}) => {
        const stepNo = () =>{
            if(step === 'view') return 1
            if(step === 'send') return 4

            return 0

        }

        return <Box sx={{ width: '100%',my:'1rem' }}>
            <Stepper activeStep={stepNo()} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
}
interface Props {
    status: TStatus
    isAlert: { isBookOver: boolean }
    serviceProvision: number
    bookCount:number
}

const SAlert = styled(Alert)(() => ({
    width:'100%',
    margin: '1em 0',
    padding: '5px',
    position: 'sticky',
    top: 0,
    '& .MuiAlert-message': {
        width: '100%'
    }
}))

const CalendarStatus: FC<Props> = ({status, isAlert, serviceProvision,bookCount}) => {
    let message = ''
    const navigate = useNavigate();

    switch (status) {
        case 'view':
            message = '予約・キャンセルを確認してください。'
            // expected output: "Mangoes and papayas are $2.79 a pound."
            break;
        case 'send':
            message = '予約が完了しました。'
            // expected output: "Mangoes and papayas are $2.79 a pound."
            break;
        default:
            message = '予約・キャンセルしたい日をクリックしてください。'
            break;
    }


    return <>
        {message.length > 0 && <>
            <CalendarStepper step={status}/>
            <SAlert sx={{textAlign:'center'}}>
                {isAlert.isBookOver && <Typography>あなたの支給量は{serviceProvision}日です。あと{serviceProvision - bookCount}日分の予約ができます。</Typography>}
                <Typography>{message}</Typography>
                {status === 'send' && <><Typography sx={{m: '1em', textAlign: 'center'}}><SecondButton label="ホームに戻る"
                                                                                                       onClick={() => {
                                                                                                           navigate('/')
                                                                                                       }}/></Typography></>}

            </SAlert>
        </>}
        {isAlert.isBookOver && <SAlert severity="warning"
                                       variant="filled">受給量(受給量：{serviceProvision}日)が一杯です。どの日かキャンセルすると、新たな予約ができます。</SAlert>}
    </>
}
export default CalendarStatus
