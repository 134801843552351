import React, {FC, useState} from 'react';
import {Box} from '@mui/material';
import {ErrorProvider} from '../ErrorContext';
import DropMenu from '../Molecules/DropMenu';
import Footer from '../Organisms/Footer';
import Header from '../Organisms/Header';

export interface GenericTemplateProps {
  children: React.ReactNode;
}

const GenericTemplate:FC<GenericTemplateProps> = ({children}) => {
  const [dropMenuOpen, setDropMenuOpen] = useState(false);
  const toggleDropMenu = () => setDropMenuOpen(!dropMenuOpen);

  return (
    <>
      <ErrorProvider>
      <Header onOpen={toggleDropMenu} />
      <DropMenu open={dropMenuOpen} onClose={toggleDropMenu}/>
      <Box sx={{minHeight:'100%'}}>
        {children}
      </Box>

      <Footer/>
      </ErrorProvider>
    </>
  )
}
export default GenericTemplate;
