//
import {FC, useState} from 'react';
import styled from '@emotion/styled';
import {Grid, Paper, Stack, TextField, Typography} from '@mui/material';
import {red} from '@mui/material/colors';
import {useNavigate,useLocation} from 'react-router-dom';
import useAuth from '../../Hooks/useAuth';
import useToken from '../../Hooks/useToken';
import Conf from '../../Types/config';
import {useError} from '../ErrorContext';
import DefaultButton from '../atom/Buttons/DefaultButton';
import BackdropLoading from '../comm/BackDrop';

const LoginBox = styled(Paper)({
    textAlign: 'center',
    minWidth: '80%',
    padding: 50
});

const Login: FC = () => {
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const {loginRequest} = useAuth()
    const {setToken} = useToken()
    const navigate = useNavigate()
    const {userMessage, setContextError} = useError()
    const location=useLocation()

    const handleLogin = () => {
        setIsLoading(true)
        const res = loginRequest(email, password)
        void res?.then(value => {
                if (value.isLogin) {
                    // todo history
                    const searchParams = new URLSearchParams(location.search);
                    const from = searchParams.get('from');
                    const redirectURL = from === null || from === '/login' ? '/' : from
                    setToken(value.user.access_token)
                    navigate(redirectURL)

                } else if (value.errorMessage && value.statusCode) {
                    void setContextError(value.statusCode, value.errorMessage)
                    setIsLoading(false)

                }
            }
        )
    }

    if (isLoading) return <BackdropLoading showBackdrop={isLoading}/>

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{minHeight: '90vh'}}
        >

            <LoginBox>
                <h1><img src="/passo-logo.fw.png" height="50" alt=""/></h1>
                <Typography sx={{my: '1em'}}>{Conf.applicationTitle}ログイン</Typography>
                {userMessage ? <Typography sx={{color: red['400'], mb: '1em'}}>{userMessage}</Typography> : ''}
                <form onSubmit={handleLogin}>
                    <Stack spacing={4}>
                        <TextField required label="ログイン ID" type="email" value={email}
                                   onChange={event => setEmail(event.target.value)} autoFocus/>
                        <TextField required label="パスワード" autoComplete="off" type="password" value={password}
                                   onChange={event => setPassword(event.target.value)}/>
                        <DefaultButton label="ログイン" onClick={() => handleLogin()} type="submit" disabled={false}/>
                    </Stack>
                </form>
            </LoginBox>
        </Grid>
    )

}
export default Login
