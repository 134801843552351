import { blue, green, red } from '@mui/material/colors';

export const week = (d: Date) => {
  switch (d.getDay()) {
    case 0:
      return 'sun';
    case 6:
      return 'sat';
    default:
      return 'default';
  }
};
export const color = {
  sun: red['50'],
  sat: blue['50'],
  default: green['600'],
};
export const backgroundColor = {
  sun: red['100'],
  sat: blue['100'],
  default: green['50'],
};
