import React, {createContext, useContext, useEffect, useState} from 'react';
import {useNavigate,useLocation} from 'react-router-dom';
import {ErrorContextInterface} from '../Types/Error';
import {useMessage} from './MessageContext';

export const ErrorContext = createContext({} as ErrorContextInterface);

type ErrorProviderProps = {
    children: React.ReactNode
}
export const ErrorProvider: React.FC<ErrorProviderProps> = ({children}) => {
    const [userMessage, setUserMessage] = useState<string | null>(null);
    const [status, setStatus] = useState<number>(0);
    const [isRedirect, setIsRedirect] = useState<boolean>(false)
    const [history, setHistory] = useState<string[]>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const {handleSetMessage} = useMessage()
    const setContextError = (statusCode: number, message: string,isGoToErrorPage=false) => {
        console.info(message)
        setStatus(statusCode);
        setUserMessage(message);
        setIsRedirect(isGoToErrorPage)
    };


    useEffect(()=> {console.log(userMessage)},[userMessage])
    useEffect(() => {
            setHistory(prevState => [...prevState, location.pathname]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ,[location])

    useEffect(() => {
        console.log(status,userMessage)
        if (status === 401) {
            navigate('/login');
        } else if (status === 500 && isRedirect) {
            navigate('/error')
        } else  if (status === 500){
            handleSetMessage('サーバエラーです。管理者に連絡してください。')
            navigate(history[history.length -1])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ navigate, setStatus, status]);

    return (
        <ErrorContext.Provider value={{status, userMessage, setContextError}}>
            {children}
        </ErrorContext.Provider>
    );
};
//
export const useError = () => useContext(ErrorContext)
