import React, {FC} from 'react';
import {Grid, Paper, styled, Typography} from '@mui/material';
import useCurriculumCell from '../../Hooks/useCurriculumCell';
import {TStatus} from '../../Types/BookStatus';
import {ICurriculum} from '../../Types/Response';
import CellDateHead from '../comm/CellDateHead';

interface Props {
    curriculums: ICurriculum[]
    status:TStatus
}

interface IBooks {
    booking: ICurriculum[]
    bookWait: ICurriculum[]
    cancel: ICurriculum[]
}

type STypographyStyleProp = {
    color: string
}

const STypography = styled(Typography)<STypographyStyleProp>(({color}) => ({
    border: `1px solid ${color}`,
    backgroundColor: color,
    radius: 30,
    color: 'white',
    padding: '1rem',
    margin: '1rem 0',
    fontSize: 'large'
}))
const Item = styled(Paper)(({theme}) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    height: '100%',
    minHeight: '5em',
    color: theme.palette.text.secondary,
}));

const BookingCompleted: FC<Props> = ({status, curriculums}) => {
    const book: IBooks = {booking: [], cancel: [], bookWait: []}
    const {label} = useCurriculumCell()
    book.booking = curriculums.filter(value => (label(value) === '予約しました。') || (value.is_edit && label(value) === '予約'))
    book.cancel = curriculums.filter(value => label(value) === '予約解除しました。'||  label(value) === 'キャンセル待ち解除しました。'|| (value.is_edit && (label(value) === '予約解除' || label(value) === 'キャンセル待ち解除')))
    book.bookWait = curriculums.filter(value => label(value) === 'キャンセル待ち予約しました。' || (value.is_edit && label(value) === 'キャンセル待ち予約'))
    const titleStrig = status === 'view' ? 'する' : 'した'
    if (status ==='input') return <></>

    return <>

        <STypography color="#4d9078">予約{titleStrig}カリキュラム</STypography>
        {book.booking.length === 0 && <Typography>予約{titleStrig}カリキュラムは、ありません。</Typography>}
        <Grid container spacing={2} sx={{mb: '2rem'}}>
            {book.booking.map((value) => (
                <Grid item key={value.id} sx={{mb:'1rem'}} xs={6} md={3}>
                    <Item>
                        <CellDateHead date={value.lecture_date}/>
                        <p>{value.name}</p>
                    </Item>
                </Grid>

            ))}
        </Grid>
        <hr/>

        <STypography color="#586496">キャンセル待ち予約{titleStrig}カリキュラム</STypography>
        {book.bookWait.length === 0 && <Typography>キャンセル待ち予約{titleStrig}カリキュラムは、ありません。</Typography>}
        <Grid container spacing={2} sx={{mb: '2rem'}}>
            {book.bookWait.map((value) => (
                <Grid item key={value.id} sx={{mb:'1rem'}} xs={6} md={3}>
                    <Item>
                        <CellDateHead date={value.lecture_date}/>
                        <p>{value.name}</p>
                    </Item>
                </Grid>
            ))}
        </Grid>
        <STypography color="#563645">キャンセル{titleStrig}カリキュラム</STypography>
        {book.cancel.length === 0 && <Typography>キャンセル{titleStrig}カリキュラムは、ありません。</Typography>}
        <Grid container spacing={2} sx={{mb: '2rem'}}>
            {book.cancel.map((value) => (
                <Grid item key={value.id} sx={{mb:'1rem'}} xs={6} md={3}>
                    <Item>
                        <CellDateHead date={value.lecture_date}/>
                        <p>{value.name}</p>
                    </Item>
                </Grid>
            ))}
        </Grid>
    </>
};

export default BookingCompleted;
