import { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled,
  Grid,
  Switch,
  FormControlLabel, Typography,
} from '@mui/material';
import useCurriculumCell from '../../Hooks/useCurriculumCell';
import useFetchcurriculums from '../../Hooks/useFetchCurriculums';
import { ICurriculum } from '../../Types/Response';
import config from '../../Types/config';
import DefaultButton from '../atom/Buttons/DefaultButton';
import CellDateHead from '../comm/CellDateHead';
import { STypography } from '../comm/CurritulumCell';
import ServiceChip from '../comm/ServiceChip';

type STableCellProps={
  displayValue:'flex' | 'table-cell'|'none'
}
const SButtonP = styled(Typography)(() => ({
  position: 'sticky',
  textAlign:'center',
  bottom: '3em',
  left: 0,
  backgroundColor:'rgba(255, 255, 255, 0.9)',
  width: '100%',
}))


const STableCell = styled(TableCell,{shouldForwardProp: (prop) =>prop !== 'displayValue'})<STableCellProps>(({displayValue})=>({
  border: '1px solid #ccc',
  padding: '0.5rem',
  minWidth: '10rem',
  textAlign: 'center',
  verticalAlign: 'top',
  display: displayValue
}))
const STable=styled(Table)(()=>({
  margin: '1rem auto',
}))
const TestPage: React.FC = () => {
  const {weeks,fetchRequest} = useFetchcurriculums()
  const {isBooking, serviceLabel,isPastDate, isServiceClose,serviceColor, chipStyle, label} = useCurriculumCell()
  const weekDay = ['one', 'two', 'three', 'four', 'five', 'six']
  const [isCalendar,setIsCalendar] = useState<boolean>(true)
  const today = new Date()

  useEffect(() => {
    const abortCtrl = new AbortController()
    fetchRequest()

    return ()=>abortCtrl.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const DisplayCell = (curriculum:ICurriculum):'flex' | 'table-cell'|'none' => {
    if(today.getMonth() !== curriculum.lecture_date.getMonth() && !isCalendar) return 'none'
    if (isCalendar) return 'table-cell'

    return 'flex'
  }

  return (
    <>
      <STypography sx={{textAlign:'right'}} cursor="default">
        <FormControlLabel control={<Switch checked={isCalendar} onClick={()=>setIsCalendar(prevState => !prevState)} />} label="カレンダー形式" /></STypography>
      <TableContainer sx={{padding: '1rem',width: '95%',margin: '0 auto 5rem auto'}}>
        <STable >
          <TableBody>
            {weeks.map((week,key) =>
              <TableRow key={`row-${weekDay[key]}`}>
                {week.map((curriculum) =>
                  <STableCell key={`row-${weekDay[key]}-${curriculum.lecture_date.getMonth()}-${curriculum.lecture_date.getDate()}`} displayValue={DisplayCell(curriculum)} valign="top">
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        {today.getMonth() === curriculum.lecture_date.getMonth() && <CellDateHead date={curriculum.lecture_date}/>}
                      </Grid>
                      <Grid item xs={12}>

                        <STypography cursor="default">{curriculum.name}</STypography>
                      </Grid>
                      {curriculum.lecture_id > 1 &&
                        <Grid item xs={12}>

                          <STypography cursor="default" sx={{
                            borderColor: serviceColor(curriculum),
                            color: serviceColor(curriculum),
                            fontSize: '90%',
                            fontWeight: '1000'
                          }}>{serviceLabel(curriculum)}</STypography></Grid>}
                      {curriculum.lecture_id > 1 && isBooking(curriculum) && <>
                        <Grid item xs={12}>
                          <ServiceChip
                            variant={chipStyle(curriculum)}
                            styleCcolor={serviceColor(curriculum)}
                            label={label(curriculum)}/>
                        </Grid>
                      </>}
                      {curriculum.lecture_id > 1 && !isPastDate(curriculum.lecture_date) && isServiceClose(curriculum) && <>
                        <Grid item xs={12}>
                          {curriculum.is_book ? '予約済み' : ''}
                        </Grid>
                      </>}
                      {curriculum.lecture_id === 7 && curriculum.url &&
                        <Grid item xs={12}><Typography sx={{textAlign:'center',my:'1em'}}><a
                          href={`${config.host}/curriculums/download/${curriculum.id}`}>{curriculum.title}</a>
                        </Typography></Grid>}
                      {curriculum.lecture_id === 7 && !curriculum.url &&
                        <Grid item xs={12}><Typography sx={{textAlign:'center',my:'1em'}}>{curriculum.title}
                        </Typography></Grid>}

                    </Grid>
                  </STableCell>
                )}
              </TableRow>)}
          </TableBody>
        </STable>
      </TableContainer>
      <SButtonP><DefaultButton label="送信"/></SButtonP>
    </>
  )
}
export default TestPage;